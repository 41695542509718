<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__item1">
        <h2 class="footer__title">ИНФОРМАЦИОННАЯ ПОДДЕРЖКА</h2>
        <p class="footer__subtitle">
          Справки и консультации по информационному наполнению системы,
          голосованию и обсуждению осуществляют секретари ТРГ (контакты
          приведены в карточке ТРГ в личном кабинете)
        </p>
      </div>
      <div class="footer__item2">
        <div class="footer__item2-soc">
          <a href="https://vk.com/burondt"><img src="/img/11.png" alt="" /></a>
          <a href="https://t.me/burondt"><img src="/img/12.png" alt="" /></a>
        </div>
        <div class="footer__item2-cont">
          <a target="_blank" href="tel:+74952400000">+7(495)240-00-00</a><br />
          <a target="_blank" href="mailto:buro.ndt@burondt.ru">buro.ndt@burondt.ru</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.footer {
  background: url("/img/footer.png") no-repeat center;
  background-size: 100% 100%;
}

.footer__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 4vh 4vh;
}
.footer__item1 {
  width: 51%;
}
.footer__title {
  font-size: 30px;
  font-size: calc(100vw / 77);
}
.footer__subtitle {
  font-size: calc(100vw / 77);
}

.footer__item2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: calc(100vw / 4);
}
.footer__item2-soc {
  width: calc(100vw / 8);
  display: flex;
  justify-content: space-around;
}
.footer__item2-soc img {
  width: calc(100vw / 29);
  transition: .5s;
}
.footer__item2-cont {
  width: calc(100vw / 7);
  text-decoration: none;
  margin: 0 auto;
  text-align: end;
  font-size: calc(100vw / 77);
}
.footer__item2-cont a {
  text-decoration: none;
  color: black;
  text-align: right;
}
.footer__item2-soc img:hover {
    transform: scale(1.1);
    cursor: pointer;
}

@media (max-width: 1440px) {
  .footer__wrapper {
    padding: 2vw;
  }
  .footer__item2-cont {
    max-width: 244px;
  }
}

/*----------------------------MOBILE---------------------------------*/

@media (max-width: 480px) {
  .main {
    height: 3000px;
  }
  .footer {
    background: url("/img/foot-bg.png") no-repeat center;
    background-size: 100% 100%;
  }
  .footer__wrapper {
    padding: 0 15px;
  }
  .footer__subtitle {
    font-size: calc(100vw / 35);
  }

  .footer__title {
    font-size: calc(100vw / 29);
  }
  .footer__item1 {
    width: 66%;
    padding: 1vw 0;
  }
  .footer__item2 {
    height: 177px;
    padding: 11px 0;
    align-items: flex-end;
  }
  .footer__item2-soc {
    width: calc(100vw / 4);
  }
  .footer__item2-soc img {
    width: calc(100vw / 17);
  }
  .footer__item2-cont {
    font-size: calc(100vw / 31);
    width: calc(100vw / 3);
  }
}
</style>